import axios from "axios";
export const REC_URL = "";

// // testing local
// export const BASE_URL = 'http://127.0.0.1:8000/api/v2';
// export const MEDIA_URL = 'http://127.0.0.1:8000';

// export const BASE_URL = 'http://192.168.1.33:8000/api/v2';
// export const MEDIA_URL = 'http://192.168.1.33:8000';

// export const BASE_URL = 'http://192.168.186.7:8000/api/v2';
// export const MEDIA_URL = 'http://192.168.186.7:8000';

// export const BASE_URL = 'http://192.168.148.7:8000/api/v2';
// export const MEDIA_URL = 'http://192.168.148.7:8000';

// export const CALL_UID = 'Jhrt98ubnnHH33tGfinm3';
// export const CALL_PIN = 'lmakn76ghyticb66';

// SAAS live server
export const BASE_URL = "https://crm-beta-api.vozlead.in/api/v2";
export const MEDIA_URL = "https://crm-beta-api.vozlead.in";

// export const BASE_URL = "https://crm-test-api.foxa.in/api/v2";
// export const MEDIA_URL = "https://crm-test-api.foxa.in";

//reports API
// export const LEAD_API_URL = 'http://127.0.0.1:8000/api/v2/account/report/lead_status/';

// FOXA CRM
// export const BASE_URL = 'https://crm-api.foxa.in/api/v2';
// export const MEDIA_URL = 'https://crm-api.foxa.in';

// testing server
// export const BASE_URL = 'https://lead-management-api.foxa.in/api/v1';
// export const MEDIA_URL = 'https://lead-management-api.foxa.in';
// export const CALL_UID = 'Jhrt98ubnnHH33tGfinm3';
// export const CALL_PIN = 'lmakn76ghyticb66';

//admin server
// export const BASE_URL = 'https://admin-lead-api.foxa.in/api/v1';
// export const MEDIA_URL = 'https://admin-lead-api.foxa.in';
// export const CALL_UID = 'xwdfi5nZes';
// export const CALL_PIN = 'pdwq6tvmEd';

//nrs builders server
// export const BASE_URL = 'https://nrs-api.foxa.in/api/v1';
// export const MEDIA_URL = 'https://nrs-api.foxa.in';
// export const CALL_UID = '';
// export const CALL_PIN = '';

//redbeats
// export const BASE_URL = 'https://redbeats-api.foxa.in/api/v1';
// export const MEDIA_URL = 'https://redbeats-api.foxa.in';

//codeme
// export const BASE_URL = 'https://codeme-lead-api.foxa.in/api/v1';
// export const MEDIA_URL = 'https://codeme-lead-api.foxa.in';
// export const CALL_UID = 'Jhrt98ubnnHH33tGfinm3';
// export const CALL_PIN = 'lmakn76ghyticb66';

//crm-temp
// export const BASE_URL = 'https://api.vozlead.in/api/v1';
// export const MEDIA_URL = 'https://api.vozlead.in/';
// export const CALL_UID = 'cvgfertysd';
// export const CALL_PIN = 'axdcfgpliw';

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: false,
});
