import useAxiosPrivate from "../hooks/useAxiosPrivate";


const useEventServices = () => {
    const axiosPrivate = useAxiosPrivate();
  
    const getEventUsers = async () => {
        const response = await axiosPrivate.get(
            "/events/users/"
        );
        return response.data;
    };

    return {getEventUsers};
};

export default useEventServices;
